import { useState, useEffect, useRef, useCallback } from "react";
import useStateRef from "../useStateRef";
import useTimer from "../useTimer";
import Webcam from "react-webcam";
import useTorchLight from "../useTorchLight";
import { isTypeSupported } from "../../utils";
import { useRouter } from "../../context/router";
import { Pages } from "../../types";

type Response = {
    webcamRef: React.MutableRefObject<Webcam>;
    webcamStarted: boolean;
    recording: boolean;
    done: boolean;
    recordedChunks: Blob[];
    hasTimeout: any;
    recordingTimer: any;
    nextPageTimer: any;
    aiTimer: number | null;
    timeoutTimer: number | null;
    setRecording: React.Dispatch<React.SetStateAction<boolean>>;
    setWebcamStarted: React.Dispatch<React.SetStateAction<boolean>>;
    setHasTimeout: any;
    setNextPageTimer: any;
    startTimeoutTimer: () => void;
    stopTimeoutTimer: () => void;
    startAiTimer: () => void;
    stopAiTimer: () => void;
    resetValues: () => void;
    callbackEndTimeout: () => void;
    handleStopCapture: () => void;
};

const useRecording = ({
    step,
    delay,
    delayedRecord = false,
    torch = false,
    isPassport = false,
}: {
    step: "doc" | "liveness";
    delay: number;
    delayedRecord?: boolean;
    torch?: boolean;
    isPassport?: boolean;
}): Response => {
    const { goto } = useRouter();
    // Refs
    const webcamRef = useRef<Webcam | null>(
        null
    ) as React.MutableRefObject<Webcam>;
    const mediaRecorderRef = useRef<MediaRecorder | null>(null);

    // Torch hook
    const [turnOn, _, available] = useTorchLight(webcamRef.current?.stream);

    // Webcam & recording status
    const [webcamStarted, setWebcamStarted] = useState<boolean>(false);
    const [recording, setRecording] = useState<boolean>(false);
    const [done, setDone] = useState<boolean>(false);

    // Blob video frames
    const [recordedChunks, setRecordedChunks] = useState<Blob[]>([]);

    // Constants
    const QUERY_INTERVAL = delay ?? 500;
    const DETECTION_TIMER = 15000;
    const RECORDING_TIME =
        step === "liveness" ? 2000 : isPassport ? 6000 : 5000;

    // Timers
    const [hasTimeout, setHasTimeout] = useStateRef(false);
    const [recordingTimer, setRecordingTimer] = useStateRef(null);
    const [nextPageTimer, setNextPageTimer] = useStateRef(null);
    const {
        timer: aiTimer,
        start: startAiTimer,
        stop: stopAiTimer,
    } = useTimer(QUERY_INTERVAL);
    const {
        timer: timeoutTimer,
        start: startTimeoutTimer,
        stop: stopTimeoutTimer,
    } = useTimer(DETECTION_TIMER);

    // Reset states values
    const resetValues = () => {
        setWebcamStarted(false);
        setRecording(false);
        setDone(false);
        setRecordedChunks([]);
        setHasTimeout(false);
        setHasTimeout(null);
    };

    // Start the timer
    const callbackEndTimeout = () => {
        setHasTimeout(true);
        stopAiTimer();
        stopTimeoutTimer();
    };

    const handleDataAvailable = useCallback(
        ({ data: frameData }: { data: Blob }) => {
            if (frameData.size > 0) {
                setRecordedChunks((prev: Blob[]) => prev.concat(frameData));
            }
        },
        [setRecordedChunks]
    );

    const handleStartRecording = useCallback(() => {
        if (torch && available) turnOn();

        const mimeType = isTypeSupported();
        const mediaRecorderOptions: MediaRecorderOptions = {
            bitsPerSecond: 6000000,
            ...(mimeType && { mimeType }),
        };
        const stream = webcamRef.current?.stream;
        if (stream) {
            try {
                mediaRecorderRef.current = new MediaRecorder(
                    stream,
                    mediaRecorderOptions
                );
            } catch (error) {
                mediaRecorderRef.current = new MediaRecorder(stream, {
                    bitsPerSecond: 6000000,
                });
            }

            if (mediaRecorderRef.current) {
                mediaRecorderRef.current.addEventListener(
                    "dataavailable",
                    handleDataAvailable
                );
                mediaRecorderRef.current.start(50);
                setRecordingTimer(RECORDING_TIME);
            } else {
                goto({
                    page: Pages.ERROR_PAGE,
                    props: {
                        message: "VideoRecordingError",
                        code: 702,
                        description: [
                            "Something went wrong while recording the video.",
                            "Failed to create MediaRecorder",
                        ],
                    },
                });
            }
        } else {
            goto({
                page: Pages.ERROR_PAGE,
                props: {
                    message: "VideoRecordingError",
                    code: 702,
                    description: [
                        "Something went wrong while recording the video.",
                        "Stream is not available",
                    ],
                },
            });
        }
    }, [
        mediaRecorderRef,
        webcamRef,
        handleDataAvailable,
        setRecordingTimer,
        torch,
        available,
    ]);

    const handleStopCapture = useCallback(() => {
        mediaRecorderRef.current!.stop();
        setRecording(false);
        setDone(true);
    }, [mediaRecorderRef, setDone]);

    // Start capturing the video when the value of recording is true
    useEffect(() => {
        if (recording) {
            setTimeout(
                () => {
                    handleStartRecording();
                },
                delayedRecord ? 3000 : 0
            );
        }
    }, [recording, handleStartRecording, delayedRecord]);

    return {
        // states
        webcamRef,
        webcamStarted,
        recording,
        done,
        recordedChunks,
        hasTimeout,
        recordingTimer,
        nextPageTimer,
        aiTimer,
        timeoutTimer,
        // setStates
        setRecording,
        setWebcamStarted,
        setHasTimeout,
        setNextPageTimer,
        // functions
        startTimeoutTimer,
        stopTimeoutTimer,
        startAiTimer,
        stopAiTimer,
        resetValues,
        callbackEndTimeout,
        handleStopCapture,
    };
};

export default useRecording;
